import "./Workshop.css"

const Workshop = () => {
    return (
        <div className="workshop-container">
            <h1>Schedule</h1>
            <h2>Releasing Soon...</h2>
            <img src="https://media.discordapp.net/attachments/1009628086377132153/1071547419147378718/Panelist_Judge_sharks.png" alt="shark talk"></img>
        </div>
    )
}

export default Workshop