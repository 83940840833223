import "./Spotify.css";

const Spotify = () => {
  return (
    <div className="spotify-container">
      <h1>BeachMuse</h1>
      <h2>Coming Soon...</h2>
      <img src="https://media.discordapp.net/attachments/1009628086377132153/1071547518653046934/Laptop_Shark.png" alt="shark music"></img>
    </div>
  );
};

export default Spotify;
