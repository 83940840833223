// create react component
import React from "react";
import "./Sponsors.css";
// import GoogleCloudIcon from "../../assets/google_cloud.svg";
// import CodeAndCoffee from "../../assets/code_and_coffee.svg";

const Sponsors = () => {
  return (
    <section className="sponsor-view" id="sponsors">
      <h1 className="title">Our Sponsors</h1>
      <div className="sponsor">
        <div className="left">
          <div className="top">
            {/* 3 squares */}
            <div className="container medium-sponsor">
              <a href="https://github.com/">
                <img
                  src={"https://i.imgur.com/T23sXbP.png"}
                  alt="Github Goes Here"
                />
              </a>
            </div>
            <div className="container medium-sponsor">
              <a href="https://cloud.google.com/">
                <img
                  src="https://i.imgur.com/3ONwKQr.png"
                  alt="Google Goes Here"
                />
              </a>
            </div>
            <div className="container medium-sponsor">
              <a href="https://www.codeandcoffee.dev/">
                <img
                  src="https://i.imgur.com/Kic5Dzq.png"
                  alt="CodeAndCoffee Goes Here"
                />
              </a>
            </div>
            <div className="container medium-sponsor">
              <a href="https://www.csulb.edu/college-of-engineering">
                <img
                  src="https://www.csulb.edu/sites/default/files/images/2line.name_.coe_.png"
                  alt="CSULB College of Engineering"
                />
              </a>
            </div>
            <div className=" medium-sponsor">
              <a href="https://www.bisecthosting.com/">
                <img
                  src={"https://i.imgur.com/jzYw45G.png"}
                  alt="Bisect Hosting Goes Here"
                />
              </a>
            </div>
            <div className=" medium-sponsor">
              <a href="https://www.digitalocean.com/">
                <img
                  src={"https://i.imgur.com/y57uRps.png"}
                  alt="Digital Ocean"
                />
              </a>
            </div>
            <div className=" medium-sponsor">
              <a href="https://www.interviewcake.com/">
                <img
                  src={"https://i.imgur.com/ytczpTs.png"}
                  alt="Interview Cake"
                />
              </a>
            </div>
            <div className=" medium-sponsor">
              <a href="https://www.echo3d.com/">
                <img src={"https://i.imgur.com/g8PxBoi.png"} alt="echo3D" />
              </a>
            </div>
            <div className=" medium-sponsor">
              <a href="https://balsamiq.com/">
                <img src={"https://i.imgur.com/uSASfPL.png"} alt="Balsamiq" />
              </a>
            </div>
            <div className=" medium-sponsor">
              <a href="https://gen.xyz/">
                <img src={"https://i.imgur.com/AmkCmVM.png"} alt=".XYZ" />
              </a>
            </div>
            <div className="container medium-sponsor">
              <a href="https://csulb.acm.org/">
                <img
                  src={"https://i.imgur.com/k6bsvfR.png"}
                  alt="ACM Hosting Goes Here"
                />
              </a>
            </div>
          </div>
          {/* <div className="bottom">
            
            <div className="container small-sponsor"></div>
            <div className="container small-sponsor"></div>
            <div className="container small-sponsor"></div>
            <div className="container small-sponsor"></div>
            <div className="container small-sponsor"></div>
            <div className="container small-sponsor"></div>
            <div className="container small-sponsor"></div>
            <div className="container small-sponsor"></div>
            <div className="container small-sponsor"></div>
          </div> */}
        </div>
        <div className="right">
          <div className="container big-sponsor">
            <a href="https://www.arduino.cc/">
              <img
                src="https://i.imgur.com/ffbxOkE.png"
                alt="Arduino goes here"
              />
            </a>
          </div>
          <a
            href="https://drive.google.com/file/d/1l3p-3Cju9sHnrNiguq4Uylv80qgGqvFM/view"
            target="_blank"
            rel="noreferrer"
          >
            <button>Become a Sponsor</button>
          </a>
        </div>
      </div>
    </section>
  );
};

// export component
export default Sponsors;
