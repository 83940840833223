export const data = [
  {
    id: 1,
    name: "Katrina",
    position: "Director",
    link: "https://i.imgur.com/jCl85BY.jpg",
  },
  {
    id: 2,
    name: "Jordan",
    position: "Sponsorship",
    link: "https://i.imgur.com/UJDXsAH.jpg",
  },
  {
    id: 3,
    name: "Katelyn",
    position: "Design",
    link: "https://i.imgur.com/DrEiyNm.jpg",
  },
  {
    id: 4,
    name: "Francine",
    position: "Internal Development",
    link: "https://i.imgur.com/VqdN5In.jpg",
  },
  {
    id: 5,
    name: "Vivian",
    position: "BeachMuse",
    link: "https://i.imgur.com/3NPDDPU.jpg",
  },
  {
    id: 6,
    name: "AlMuntaser",
    position: "Community",
    link: "https://i.imgur.com/2d5Qwx8.jpg",
  },
  {
    id: 7,
    name: "Jolene",
    position: "Operations",
    link: "https://i.imgur.com/0a43r7G.jpg",
  },
  {
    id: 8,
    name: "Leslie",
    position: "Schedule Alerts",
    link: "https://i.imgur.com/PxGcl2p.png",
  },
  {
    id: 9,
    name: "Dustin",
    position: "Logistics",
    link: "https://i.imgur.com/cHf10DN.jpg",
  },
  {
    id: 10,
    name: "Lydia",
    position: "Website",
    link: "https://i.imgur.com/beIh0mS.jpg",
  },
  {
    id: 11,
    name: "Chloee",
    position: "Finance",
    link: "https://i.imgur.com/WS7Le6e.jpg",
  },
  {
    id: 12,
    name: "Haley",
    position: "Design",
    link: "https://i.imgur.com/JStr4aL.jpg",
  },
  {
    id: 13,
    name: "Ben",
    position: "",
    link: "https://media.discordapp.net/attachments/1009628086377132153/1071547517990346822/Linkedin_Shark_2.png",
  },
  {
    id: 14,
    name: "Helen",
    position: "Illustrator",
    link: "https://i.imgur.com/DF1o6k4.jpg",
  },
  {
    id: 15,
    name: "Jackie",
    position: "Graphic Designer",
    link: "https://media.discordapp.net/attachments/1009628086377132153/1071547517990346822/Linkedin_Shark_2.png",
  },
  {
    id: 15,
    name: "Grant",
    position: "Manager",
    link: "https://i.imgur.com/Hp7BrD7.png",
  },
  {
    id: 16,
    name: "Sharon",
    position: "",
    link: "https://i.imgur.com/Nz6bkcu.jpg",
  },
  {
    id: 17,
    name: "Grant",
    position: "",
    link: "https://i.imgur.com/Hp7BrD7.png",
  },
  {
    id: 18,
    name: "Dorothy",
    position: "",
    link: "https://media.discordapp.net/attachments/1009628086377132153/1071547517990346822/Linkedin_Shark_2.png",
  },
  {
    id: 19,
    name: "Daniel",
    position: "",
    link: "https://i.imgur.com/pAWO98k.jpg",
  },
  {
    id: 20,
    name: "Mark",
    position: "",
    link: "https://i.imgur.com/1hve940.png",
  },
  {
    id: 21,
    name: "Shriyansh",
    position: "",
    link: "https://i.imgur.com/UWgh7kY.png?1",
  },
  {
    id: 22,
    name: "Reyna",
    position: "",
    link: "https://i.imgur.com/OdboNHn.jpg",
  },
  {
    id: 23,
    name: "Peter",
    position: "",
    link: "https://i.imgur.com/1kuxznU.jpg",
  },
  {
    id: 24,
    name: "Froilan",
    position: "",
    link: "https://i.imgur.com/1siLjXl.png",
  },
  {
    id: 25,
    name: "Miguel",
    // position: "Position",
    link: "https://media.discordapp.net/attachments/1009628086377132153/1071547517990346822/Linkedin_Shark_2.png",
  },
  {
    id: 26,
    name: "Kelly",
    // position: "Position",
    link: "https://media.discordapp.net/attachments/1009628086377132153/1071547517990346822/Linkedin_Shark_2.png",
  },
  {
    id: 27,
    name: "Alvin",
    position: "Designer",
    link: "https://i.imgur.com/8qYG8GH.jpg",
  },
  {
    id: 28,
    name: "Kay",
    position: "Designer",
    link: "https://i.imgur.com/8Dj5NbZ.jpg",
  },
  {
    id: 29,
    name: "Tiffany",
    position: "Designer",
    link: "https://i.imgur.com/9CSQQwv.jpg",
  },
  {
    id: 30,
    name: "Porter",
    position: "Developer",
    link: "https://i.imgur.com/Vu1vmPL.jpg",
  },
  {
    id: 31,
    name: "Jazmin",
    position: "Developer",
    link: "https://i.imgur.com/dcGteWv.jpg",
  },
  {
    id: 32,
    name: "Thoi",
    position: "Developer",
    link: "https://i.imgur.com/AZvKQL4.png",
  },
  {
    id: 33,
    name: "Brian",
    position: "Developer",
    link: "https://i.imgur.com/ECkHor6.jpg",
  },
  {
    id: 34,
    name: "Diego",
    position: "Developer",
    link: "https://i.imgur.com/MmLNUak.png",
  },
  {
    id: 35,
    name: "Rodolfo",
    position: "Developer",
    link: "https://i.imgur.com/sjC4Xkm.jpg",
  },
  {
    id: 36,
    name: "Aaron",
    position: "Developer",
    link: "https://i.imgur.com/HrHaMe4.jpg",
  },
  {
    id: 37,
    name: "Victor",
    position: "Developer",
    link: "https://media.discordapp.net/attachments/1009628086377132153/1071547517990346822/Linkedin_Shark_2.png",
  },
  {
    id: 38,
    name: "Jonathan",
    position: "Developer",
    link: "https://media.discordapp.net/attachments/1009628086377132153/1071547517990346822/Linkedin_Shark_2.png",
  },

  {
    id: 39,
    name: "Quan",
    position: "Developer",
    link: "https://i.imgur.com/aajgSPa.jpg",
  },
  {
    id: 40,
    name: "Mayank",
    position: "Developer",
    link: "https://i.imgur.com/lIJiAhf.jpg",
  },
  {
    id: 41,
    name: "James",
    position: "Developer",
    link: "https://imgur.com/LDtSg37",
  },
  {
    id: 42,
    name: "Jonic",
    position: "Developer",
    link: "https://i.imgur.com/SZAwTsn.png",
  },
  {
    id: 43,
    name: "Katelyn",
    position: "Designer",
    link: "https://i.imgur.com/DrEiyNm.jpg",
  },

  {
    id: 44,
    name: "Jenna",
    position: "Designer",
    link: "https://cdn.discordapp.com/attachments/1065519315236950016/1070207553201520700/IMG_3306.jpg",
  },
  {
    id: 45,
    name: "Dening",
    position: "Developer",
    link: "https://i.imgur.com/wSlToda.jpg",
  },
  {
    id: 46,
    name: "Mekhi",
    position: "Developer",
    link: "https://i.imgur.com/Cxlfy3p.jpg",
  },
  {
    id: 47,
    name: "Carine",
    position: "Developer",
    link: "https://i.imgur.com/lnpV4Sx.jpg",
  },
  {
    id: 48,
    name: "Jasmine",
    position: "Designer",
    link: "https://i.imgur.com/GUOVC8U.jpg",
  },
];
