import "./NoPage.css"

const NoPage = () => {
    return (
        <div className="page-cont">
            <h1>Error 404: Page Not Found</h1>
            <img src="https://media.discordapp.net/attachments/1009628086377132153/1071547419361300620/Questionmark_Shark.png" alt="shark lost"></img>
        </div>
    );
}
export default NoPage;